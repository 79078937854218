body {
    margin: 0;
    padding: 0;
    height: 100vh;
    background: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    background-size: 200% 200%; /* Increase the size to allow for movement */
    animation: gradientAnimation 5s linear infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.gatepassform{
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    max-width: 78rem;
    margin: 0 auto;
}
.logo{
    width: 12rem;
}

.gatepasstext{
    vertical-align: center;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

#gatePassTablelayout td,
#newgatePassTable td,
.packingslipLayout td
{
    padding-left: 5px;
    padding-right: 5px;
    vertical-align: super;
}


#gatePassTablelayout tr td,
#newgatePassTable tr td,
.packingslipLayout tr td {
    border: 1px solid black;
  }

  .downloading-spinner{
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px !important;
  }
  .gplist-header button{
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-weight: 700;
  }
  .gpcomment-pdffield{
    max-width: 2rem;
    line-break: auto;
  }
  .alertinfo{
    width: max-content;
    margin: 0 auto;
    margin-top: 20px;
  }

  .pagination{
    max-width: 30rem;
    overflow-y: hidden;
  }

 /* edit form gatepass  */
  .edit-form .form-label{
        margin: 0px;
    }
    .edit-form  input{
        margin-bottom: 6px;
    }